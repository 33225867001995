import React from 'react'
import { injectIntl } from 'react-intl'

import withContext from '../components/withContext'
import injectContext from '../components/injectContext'
import withIntl from '../components/withIntl'
import Space from '../components/Space'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Navigation from '../scenes/Navigation'
import Content from '../scenes/404/Content'
import Footer from '../scenes/Footer'

const NotFoundPage = ({ intl: { formatMessage } }) => (
  <Layout>
    <SEO
      metaTitle={formatMessage({ id: 'meta.404.title' })}
      metaDescription={formatMessage({ id: 'meta.404.description' })}
    />
    <Navigation />
    <Space type="giant" />
    <Content />
    <Space type="giant" />
    <Footer />
  </Layout>
)

export default withContext(injectContext(withIntl(injectIntl(NotFoundPage))))
