import './style.css'
import React, { Component } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'

import Button from '../../../components/Button'
import I18nLink from '../../../components/I18nLink'
import Space from '../../../components/Space'

import smallArrowLeftBlueIcon from '../../../img/icons/icon_small_arrow_left_blue.svg'

const containerClass = classNames({
  'not-found-content-container': true,
})

const titleTextClass = classNames({
  'not-found-title-text': true,
})

const descriptionTextClass = classNames({
  'not-found-description-text': true,
})

class Content extends Component {

  render() {
    const {
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <div className={containerClass}>
        <h1 className={titleTextClass}
        >
          {formatMessage({ id: '404.content.title' })}
        </h1>
        <Space tytpe="m" />
        <p className={descriptionTextClass}>
          {formatMessage({ id: '404.content.description' })}
        </p>
        <Space tytpe="s" />
        <I18nLink to="/">
          <Button
            text={formatMessage({ id: '404.content.action.goBack' })}
            color="outline"
            iconLeft={smallArrowLeftBlueIcon}
          />
        </I18nLink>
      </div>
    )
  }
}

export default injectIntl(Content)
